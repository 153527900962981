import Check from '@assets/icons/fi-rr-check-white.svg';
import Cross from '@assets/icons/fi-rr-cross-small-white.svg';
import Door from '@assets/icons/fi-rr-door.svg';

import styles from './SweetAlert.module.css';

type SweetAlertProps = {
  icon?: 'success' | 'error' | 'leave';
  title?: string;
  buttons?: { resolveText: string; rejectText: string };

  open: boolean;
  fadeOut: boolean;
  message: string;
  handleResolve: () => void;
  handleReject: () => void;

  preset?: 'default' | 'skewLeft';
};

export const SweetAlert = ({
  icon = 'success',
  title,
  buttons,
  open,
  fadeOut,
  message,
  handleResolve,
  handleReject,
  preset = 'default',
}: SweetAlertProps): JSX.Element => {
  return open ? (
    <div
      tabIndex={0}
      role="button"
      style={{ outline: 'none' }}
      onKeyDown={(e) => {
        e.stopPropagation();
        handleReject();
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleReject();
      }}
      className={`${styles.overlay} ${!fadeOut ? '' : styles.swalOut}`}
    >
      <div
        className={`${styles.swalDecoration} ${icon === 'success' ? 'bg-primary' : 'bg-secondary'}`}
      />

      <button
        onClick={(e) => e.stopPropagation()}
        className={`${styles.swalcontent} ${!fadeOut ? styles.swalIn : ''} ${
          buttons ? 'h-[260px]' : ''
        }`}
        style={{
          outline: 'none',
        }}
      >
        <div className="flex flex-col items-center">
          <div className={`${styles.logo} ${icon === 'success' ? 'bg-primary' : 'bg-secondary'}`}>
            {icon == 'success' && <Check width={'40px'} height={'40px'} />}
            {icon == 'error' && <Cross width={'40px'} height={'40px'} />}
            {icon == 'leave' && <Door width={'40px'} height={'40px'} />}
          </div>

          <div className={styles.title}>
            {title ? title : icon == 'success' ? 'Success' : 'Oops..'}
          </div>

          <div className={styles.message}>{message}</div>

          {buttons && (
            <div className={styles.buttonContainer}>
              <button
                tabIndex={0}
                onClick={handleReject}
                className={`bg-gray-4 ${
                  preset === 'default' ? styles.button : styles.buttonRejectSmall
                }`}
                style={{ outline: 'none' }}
              >
                {buttons.rejectText}
              </button>

              <button
                tabIndex={0}
                onClick={handleResolve}
                className={`text-white ${icon === 'success' ? 'bg-primary' : 'bg-secondary'} ${
                  preset === 'default' ? styles.button : styles.buttonResolveLarge
                }`}
                style={{
                  outline: 'none',
                }}
              >
                {buttons.resolveText}
              </button>
            </div>
          )}
        </div>
      </button>
    </div>
  ) : null;
};
