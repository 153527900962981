import { init, InitConfig } from 'next-firebase-auth';
import firebase from 'firebase/app';

export const authConfig: InitConfig = {
  authPageURL: '/login',
  appPageURL: '/verification',
  loginAPIEndpoint: '/api/login', // required
  logoutAPIEndpoint: '/api/logout', // required
  //firebaseAuthEmulatorHost: 'localhost:9099',
  // Required in most cases.
  firebaseAdminInitConfig: {
    credential: {
      projectId: 'socles',
      clientEmail: 'firebase-adminsdk-1m9ih@socles.iam.gserviceaccount.com',
      // The private key must not be accesssible on the client side.
      privateKey: process.env.FIREBASE_PRIVATE_KEY
        ? process.env.FIREBASE_PRIVATE_KEY.replace(/\\n/g, '\n')
        : undefined,
    },
    databaseURL: 'https://socles-default-rtdb.asia-southeast1.firebasedatabase.app/',
  },
  firebaseClientInitConfig: {
    apiKey: 'AIzaSyCnYYp3PifwPsdssqlBg0K_jKKcib5oedI',
    authDomain: 'socles.firebaseapp.com',
    databaseURL: 'https://socles-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'socles',
    storageBucket: 'socles.appspot.com',
    messagingSenderId: '821198515228',
    appId: '1:821198515228:web:ccd05a8466dc7bb05c83d9',
    measurementId: 'G-T4N344B72D',
  },
  cookies: {
    name: 'socles', // required
    // Keys are required unless you set `signed` to `false`.
    // The keys cannot be accessible on the client side.
    keys: [process.env.COOKIE_SECRET_CURRENT, process.env.COOKIE_SECRET_PREVIOUS],
    httpOnly: true,
    maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
    overwrite: true,
    path: '/',
    sameSite: 'strict',
    secure: process.env.NODE_ENV == 'production', // set this to false in local (non-HTTPS) development
    signed: false,
  },
};

export const makeConverter = <T>(): firebase.firestore.FirestoreDataConverter<T> => ({
  toFirestore(modelObject: T) {
    return { ...modelObject };
  },
  fromFirestore(snapshot, options): T {
    const data = snapshot.data(options)!;
    return { ...data } as T;
  },
});

const initFirebaseAuth = () => init(authConfig);
export default initFirebaseAuth;
