import { Experience } from '@types';

export const EXPERIENCES: Experience[] = [
  {
    title: 'Socles Virtual Date 💖',
    timeStart: new Date('2022-09-16T19:00:00.000+07:00'),
    timeEnd: new Date('2022-09-16T21:00:00.000+07:00'),
    description:
      'Socles Virtual Date is a special event from us to help you meet your destiny. In this exclusive event, you will be able to meet potential partners with similar interests from ITB, UNPAR, or Binus Bandung.',
    imageSrc: '/assets/images/experience/virtual-blind-date.jpg',
    pagePath: '/experience/virtual-date',
    buttonText: 'Register Here',
  },
];
