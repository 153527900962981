import { useState, useEffect, useCallback } from 'react';
import useSWRImmutable from 'swr/immutable';
import { useAuthUser } from 'next-firebase-auth';

import { API_URL } from '@constant';
import { Paginated } from '@types';

import { fetchWithToken } from './fetchWithToken';

export const FetchCategories = () => {
  const AuthUser = useAuthUser();
  const [bearer, setBearer] = useState('');

  const updateToken = useCallback(
    () =>
      AuthUser.id
        ? AuthUser.getIdToken().then((token) => setBearer(`Bearer ${token}`))
        : setBearer(''),
    [AuthUser]
  );

  useEffect(() => {
    if (AuthUser.id) updateToken();
  }, [AuthUser, updateToken]);

  const { data, error, isValidating } = useSWRImmutable<Paginated<Interest>>(
    // if logged in, wait for idToken --> benefits BE cache
    !AuthUser.clientInitialized
      ? null
      : AuthUser.id && bearer
      ? [`${API_URL}/circle/interests/?limit=200`, bearer]
      : !AuthUser.id
      ? [`${API_URL}/circle/interests/?limit=200`]
      : null,
    fetchWithToken
  );

  return {
    category: data,
    isCategoryLoaded: (!error && !data) || isValidating,
    isCategoryError: error,
  };
};
