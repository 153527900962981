import React from 'react';
import { useRouter } from 'next/router';
import { useLocale } from 'next-intl';

export const LanguageSwitcher = () => {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const locale = useLocale();

  const setLocale = (lang: 'en' | 'id') => {
    router.push({ pathname, query }, asPath, { locale: lang });
  };

  return (
    <div className="flex items-center fixed bottom-4 right-4 space-x-1 bg-gray-2 bg-opacity-30 rounded-md px-2">
      <button onClick={() => setLocale('en')} className={locale === 'en' ? 'text-primary' : ''}>
        en
      </button>
      <p>|</p>
      <button onClick={() => setLocale('id')} className={locale === 'id' ? 'text-primary' : ''}>
        id
      </button>
    </div>
  );
};
