import { useState, useEffect, useCallback, useMemo } from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useAuthUser, withAuthUser } from 'next-firebase-auth';
import { SWRConfig } from 'swr';
import { SWRInfiniteConfiguration } from 'swr/infinite';
import { createGlobalState } from 'react-hooks-global-state';
import { toast, ToastContainer } from 'react-toastify';
import { BroadcastChannel } from 'broadcast-channel';
import firebase from 'firebase/app';
import 'firebase/auth';
import { StreamChat } from 'stream-chat';

import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';
import '@styles/globals.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { FetchCategories, FetchUserData } from '@api/swr';
import { LanguageSwitcher, Loading } from '@components/elements';
import { LeftContainer, SideNav, TopNavbar } from '@components/layouts';
import { SweetAlertProvider } from '@components/modules';
import { CategoryContext, UserContext, HomeContext } from '@context';
import { isMobile } from '@utils/Device';
import initFirebaseAuth from '@utils/Firebase';
import loadFonts from '@utils/loadFonts';
import { WebView } from '@utils/WebView';
import { initGA } from '@utils/Analytics';
import { getCustomToken, getGetstreamToken } from '@api/ssr';
import { NextIntlClientProvider } from 'next-intl';

import { EXPERIENCES } from '@constant';

initFirebaseAuth();

const experiencePaths = EXPERIENCES.map((e) => e.pagePath);
const unauthedOnly = ['/', '/login', '/signup', '/privacy', '/aboutus', '/landing'];
const eitherCondition = [
  '/____',
  '/invitation',
  '/mobile',
  '/app-login',
  '/app-early-access',
  '/contact-us',
  '/experience',
  '/blind-date-form',
  '/terms-of-use',
  '/join-party',
  '/waiting-list',
  ...experiencePaths,
];
const tcouPrefix = '/card-of-us';

/** other pages that is not a part of this variable is `authedOnly` */
const openPages = unauthedOnly.concat(eitherCondition);

const SWRConfiguration: SWRInfiniteConfiguration = {
  initialSize: 1,
  revalidateAll: true,
  persistSize: true,
  revalidateOnFocus: false,
  dedupingInterval: 2000,
  loadingTimeout: 3000,
  // suspense mode is not available for SSR
  suspense: false,
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // Never retry on 404.
    if (error.status === 404) return;

    // Only retry up to 10 times.
    if (retryCount >= 10) return;

    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000);
  },
};

const initialState = {
  location: null,
  swal: {
    open: false,
    fadeOut: false,
    message: '',
    options: null as SweetAlertOptions,
  },
};
const { useGlobalState } = createGlobalState(initialState);
const getstreamClient = StreamChat.getInstance('wwvtvj9875bq');

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const AuthUser = useAuthUser();
  const router = useRouter();
  // const [location, setLocation] = useGlobalState('location');
  // const [loadingLocation, setLoadingLocation] = useState(true);

  const { pathname, query } = router;
  const [showLayout, setShowLayout] = useState<boolean>(null);
  const [user, setUser] = useState<CurrentUser>({
    username: '',
    avatar: '',
    interests: [],
  } as CurrentUser);

  // const [showActivity, setShowActivity] = useState(false);
  // const [showNotif, setShowNotif] = useState(false);
  // const [showProfile, setShowProfile] = useState(false);
  // const [getstreamConnected, setGetstreamConnected] = useState(false);

  // const [homeContext, setHomeContext] = useState({ tooltip: false });

  // Wait for fonts to load.
  // const [fontsLoaded, setFontsLoaded] = useState(false);

  // const showPosition: PositionCallback = useCallback(
  //   (position) => {
  //     setLocation(position);
  //     setLoadingLocation(false);
  //   },
  //   [setLocation]
  // );

  // const handleError: PositionErrorCallback = useCallback(() => {
  //   setLocation(null);
  //   setLoadingLocation(false);
  // }, [setLocation]);

  const { userData } = FetchUserData();
  const { category } = FetchCategories();

  // const connectGetstream = useCallback(
  //   async (user: CurrentUser) => {
  //     if (getstreamConnected) await getstreamClient.disconnectUser();
  //     await getstreamClient.connectUser(
  //       {
  //         id: user.uid,
  //         name: user.name,
  //         image: user.avatar,
  //       },
  //       () => getGetstreamToken(AuthUser)
  //     );

  //     setGetstreamConnected(true);
  //   },
  //   [AuthUser, getstreamConnected]
  // );

  useEffect(() => {
    if (userData) {
      setUser(userData);
      // connectGetstream(userData);
    }
  }, [userData]);

  useEffect(() => {
    loadFonts(); //.then(() => setFontsLoaded(true));
    initGA(process.env.NEXT_PUBLIC_GA_TRACKING_ID || 'GTM-NNR5XC5');
  }, []);

  // useEffect(() => {
  //   if (
  //     pathname !== 'app-login' &&
  //     !openPages.includes(pathname) &&
  //     !pathname.startsWith(tcouPrefix) &&
  //     pathname !== '/onboard' &&
  //     pathname !== '/application' &&
  //     (isMobile() || window.innerWidth <= 640)
  //   ) {
  //     router.replace('/mobile');
  //   }
  // }, [pathname]);

  useEffect(() => {
    const channel = new BroadcastChannel('signout');
    channel.addEventListener('message', async (msg) => {
      if (msg === AuthUser.id) {
        WebView.sendMessage('logout');
        await AuthUser.signOut();
        window.location.href = '/login';
      }
    });

    return () => {
      channel.close();
    };
  }, [AuthUser]);

  // const changeTitle = useCallback(
  //   (title) => {
  //     router.replace({ pathname, query: { ...query, subpage: title } });
  //   },
  //   [router, pathname, query]
  // );

  return (
    <NextIntlClientProvider locale={router.locale} messages={pageProps.messages}>
      <SweetAlertProvider>
        <SWRConfig value={SWRConfiguration}>
          <UserContext.Provider value={user}>
            <CategoryContext.Provider value={(category && category.results) || []}>
              {AuthUser.clientInitialized ? (
                <>
                  <div className="relative z-10 overflow-hidden bg-gray-5 p-0">
                    <Component {...pageProps} />
                    <LanguageSwitcher />
                  </div>
                  <ToastContainer
                    position="top-right"
                    autoClose={false}
                    closeOnClick
                    pauseOnFocusLoss
                    className="font-semibold"
                    theme="colored"
                    limit={5}
                    style={{ opacity: 1 }}
                  />
                </>
              ) : (
                <Loading />
              )}
            </CategoryContext.Provider>
          </UserContext.Provider>
        </SWRConfig>
      </SweetAlertProvider>
    </NextIntlClientProvider>
  );

  // useEffect(() => {
  //   if (AuthUser.clientInitialized) {
  //     if (!AuthUser.id && !openPages.includes(pathname)) {
  //       toast('Please login or signup to continue', { type: 'error' });
  //       router.push('/login?next=' + router.asPath);
  //       return;
  //     }

  //     if (!AuthUser.id) {
  //       return;
  //     }
  //     // USER IS AUTHENTICATED

  //     // If authenticated, wait for user data to load
  //     if (!user || !AuthUser.firebaseUser) return;
  //     // USER DATA IS LOADED

  //     if (pathname === '/privacy') {
  //       router.push(`/${user.username}?subpage=Privacy+Policy`);
  //       return;
  //     }

  //     // we visit unauthed page while logged in, redirect
  //     if (unauthedOnly.includes(pathname)) {
  //       if (user.status === 'ONBOARDED') {
  //         router.push('/home');
  //       } else if (user.status === 'APPROVED') {
  //         router.push('/onboard');
  //       } else if (!AuthUser.firebaseUser?.emailVerified) {
  //         router.push('/application');
  //       }
  //       return;
  //     }
  //   }
  // }, [AuthUser, user, pathname]);

  // useEffect(() => {
  //   if (
  //     pathname === '/application' ||
  //     pathname === '/onboard' ||
  //     pathname === '/____' ||
  //     pathname === '/invitation' ||
  //     pathname === '/app-early-access' ||
  //     pathname === '/contact-us' ||
  //     pathname === '/experience' ||
  //     pathname === '/blind-date-form' ||
  //     pathname === '/mobile' ||
  //     experiencePaths.includes(pathname) ||
  //     !AuthUser.id
  //   ) {
  //     setShowLayout(false);
  //   } else {
  //     setShowLayout(true);
  //   }
  // }, [pathname, AuthUser.id]);

  // // pathname !== '/____' &&
  // //     pathname !== '/application' &&
  // //     ((AuthUser.clientInitialized &&
  // //       AuthUser.id && (user && user.status !== 'APPROVED' && user.status !== 'ONBOARDED')),

  // // will redirect if
  // // the user's logged in, and is not verified, or account status is not onboarded
  // const willRedirectToApplication = useMemo(
  //   () =>
  //     pathname !== '/____' &&
  //     pathname !== '/application' &&
  //     !pathname.includes(tcouPrefix) &&
  //     AuthUser.clientInitialized &&
  //     AuthUser.firebaseUser &&
  //     (!AuthUser.firebaseUser.emailVerified ||
  //       (user && user.status !== 'ONBOARDED' && user.status !== 'APPROVED')),
  //   [user, AuthUser, pathname]
  // );

  // if (willRedirectToApplication) {
  //   router.replace('/application');
  // }

  // const redirectToOnboarding = useMemo(
  //   () =>
  //     user &&
  //     user.status === 'APPROVED' &&
  //     pathname !== '/onboard' &&
  //     AuthUser.firebaseUser &&
  //     AuthUser.firebaseUser.emailVerified,
  //   [user, pathname, AuthUser]
  // );

  // if (redirectToOnboarding) {
  //   router.replace('/onboard');
  // }

  // const redirectingFromOnboarding = useMemo(
  //   () =>
  //     user && user.interests.length === 5 && user.status === 'ONBOARDED' && pathname === '/onboard',
  //   [pathname, user]
  // );

  // if (redirectingFromOnboarding) {
  //   router.replace('/home');
  // }

  // const needLocation = useMemo(
  //   () =>
  //     pathname !== '/onboard' &&
  //     pathname !== '/____' &&
  //     pathname !== '/application' &&
  //     pathname !== '/mobile' &&
  //     !willRedirectToApplication &&
  //     !redirectToOnboarding,
  //   [pathname, willRedirectToApplication, redirectToOnboarding]
  // );

  // // authed page only
  // useEffect(() => {
  //   if (
  //     !location &&
  //     needLocation &&
  //     AuthUser.firebaseUser &&
  //     AuthUser.firebaseUser.emailVerified &&
  //     (!openPages.includes(pathname) ||
  //       (eitherCondition.includes(pathname) && AuthUser.firebaseUser))
  //   ) {
  //     setLoadingLocation(true);
  //     navigator.geolocation.getCurrentPosition(showPosition, handleError);
  //   } else {
  //     setLoadingLocation(false);
  //   }
  // }, [AuthUser, location, pathname, needLocation, showPosition, handleError]);

  // useEffect(() => {
  //   if (pathname === '/login') {
  //     WebView.sendMessage('login');
  //   } else if (pathname === '/onboard') {
  //     WebView.sendMessage('onboarding');
  //   } else if (pathname === '/home') {
  //     WebView.sendMessage('home');
  //   } else if (pathname === '/application') {
  //     WebView.sendMessage('application');
  //   }
  // }, [pathname]);

  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       user
  //         .getIdToken()
  //         .then((idToken) => getCustomToken(idToken))
  //         .then((customToken) => WebView.sendMessage('login', customToken));
  //     }
  //   });

  //   return () => unsubscribe();
  // }, []);

  // let isLoading: boolean =
  //   // loading while redirecting
  //   willRedirectToApplication ||
  //   redirectToOnboarding ||
  //   redirectingFromOnboarding ||
  //   // we opened authed only pages,
  //   // - should be redirecting to login if unauthed,
  //   // - waiting for our data to be fetched if authed
  //   (!openPages.includes(pathname) && (!AuthUser.id || !user)) ||
  //   // going to eitherCondition while authed
  //   // - wait for user to load
  //   (eitherCondition.includes(pathname) && !!AuthUser.id && !user) ||
  //   // we opened unauthed only pages
  //   // - should be redirecting to home if authed, (line 131), so render loading
  //   (unauthedOnly.includes(pathname) && !!AuthUser.id);

  // // opening unauthed page while unauthed or
  // // opening eitherConditions page while unauthed or
  // // opening tcou page
  // // - render page
  // if (
  //   ((unauthedOnly.includes(pathname) || eitherCondition.includes(pathname)) && !AuthUser.id) ||
  //   pathname.startsWith(tcouPrefix)
  // ) {
  //   return (
  //     <SweetAlertProvider>
  //       <CategoryContext.Provider value={(category && category.results) || []}>
  //         {isLoading ? (
  //           <Loading />
  //         ) : (
  //           <>
  //             <div className="relative z-10 overflow-hidden bg-gray-5 p-0">
  //               <Component {...pageProps} />
  //             </div>
  //             <ToastContainer
  //               position="top-right"
  //               autoClose={false}
  //               closeOnClick
  //               pauseOnFocusLoss
  //               className="font-semibold"
  //               theme="colored"
  //               limit={5}
  //               style={{ opacity: 1 }}
  //             />
  //           </>
  //         )}
  //       </CategoryContext.Provider>
  //     </SweetAlertProvider>
  //   );
  // }

  // isLoading =
  //   // fonts still loding
  //   !fontsLoaded ||
  //   // firabse auth not initializer
  //   !AuthUser.clientInitialized ||
  //   // waiting for common grounds to load
  //   !category ||
  //   !user ||
  //   (loadingLocation && needLocation && !location);

  // return (
  //   <>
  //     <Head>
  //       <title>The Social Circles</title>
  //     </Head>

  //     <OneSignalComponent />
  //     <SweetAlertProvider>
  //       <SWRConfig value={SWRConfiguration}>
  //         {isLoading ? (
  //           <Loading />
  //         ) : (
  //           <UserContext.Provider value={user}>
  //             <CategoryContext.Provider value={category && category.results}>
  //               <HomeContext.Provider value={{ ...homeContext, update: setHomeContext }}>
  //                 <div
  //                   className="outline-none cursor-default"
  //                   role="button"
  //                   tabIndex={0}
  //                   onClick={() => {
  //                     setShowActivity(false);
  //                     setShowNotif(false);
  //                     setShowProfile(false);
  //                   }}
  //                   onKeyDown={(e) => e.stopPropagation()}
  //                 >
  //                   {showLayout && <SideNav />}

  //                   <ToastContainer
  //                     position="top-right"
  //                     autoClose={false}
  //                     closeOnClick
  //                     pauseOnFocusLoss
  //                     className="text-white font-semibold"
  //                   />

  //                   <IdleHandlerComponent />

  //                   {showLayout && <LeftContainer eventhandler={changeTitle} />}

  //                   <div>
  //                     <Component {...pageProps} eventhandler={changeTitle} />
  //                   </div>

  //                   {showLayout && (
  //                     <TopNavbar
  //                       showActivity={showActivity}
  //                       setShowActivity={setShowActivity}
  //                       showNotif={showNotif}
  //                       setShowNotif={setShowNotif}
  //                       showProfile={showProfile}
  //                       setShowProfile={setShowProfile}
  //                     />
  //                   )}
  //                 </div>
  //               </HomeContext.Provider>
  //             </CategoryContext.Provider>
  //           </UserContext.Provider>
  //         )}
  //       </SWRConfig>
  //     </SweetAlertProvider>
  //   </>
  // );
};

export default withAuthUser()(MyApp as any);
export { useGlobalState };
