import FontFaceObserver from 'fontfaceobserver';

const loadFonts = async () => {
  const avenirLt400 = new FontFaceObserver('AvenirLT', { weight: 400 });
  const avenirLt500 = new FontFaceObserver('AvenirLT', { weight: 500 });
  const avenirLt600 = new FontFaceObserver('AvenirLT', { weight: 600 });
  const avenirLt900 = new FontFaceObserver('AvenirLT', { weight: 900 });

  const avenir200 = new FontFaceObserver('Avenir', { weight: 200 });
  const avenir300 = new FontFaceObserver('Avenir', { weight: 300 });
  const avenir400 = new FontFaceObserver('Avenir', { weight: 400 });
  const avenir500 = new FontFaceObserver('Avenir', { weight: 500 });
  const avenir600 = new FontFaceObserver('Avenir', { weight: 600 });
  const avenir900 = new FontFaceObserver('Avenir', { weight: 900 });

  const socles = new FontFaceObserver('Socles', { weight: 400 });

  const playfair = new FontFaceObserver('Playfair Display', { weight: 600 });

  return await Promise.all([
    avenir300.load('', 15000),
    avenir400.load('', 15000),
    avenir500.load('', 15000),
    avenir600.load('', 15000),
    socles.load('', 15000),
    playfair.load('', 15000),
  ]);
};

export default loadFonts;
