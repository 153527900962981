import { useGlobalState } from 'pages/_app';
import * as React from 'react';
import { SweetAlert } from './SweetAlert';

type SweetAlertProviderProps = {
  children: React.ReactNode;
};

const sleep = (m: number): Promise<void> => new Promise((r) => setTimeout(r, m));

export const SweetAlertContext = React.createContext({
  swal: async (_message: string, _option?: SweetAlertOptions) => null,
  closeSwal: () => {
    //
  },
});

export const useSweetAlert = (): {
  swal: (message: string, option?: SweetAlertOptions) => Promise<null>;
  closeSwal: () => void;
} => React.useContext(SweetAlertContext);

export const SweetAlertProvider = ({ children }: SweetAlertProviderProps): JSX.Element => {
  const [state, setState] = useGlobalState('swal');

  const awaitingPromiseRef = React.useRef<{
    resolve: (value: PromiseLike<null>) => void;
    reject: (value: unknown) => void;
  }>();

  const closeSwal = React.useCallback(() => {
    setState({ ...state, fadeOut: true });
    setTimeout(() => {
      setState({ ...state, open: false, fadeOut: false });
    }, 75);
  }, []);

  const swal = React.useCallback(
    async (message: string, option?: SweetAlertOptions): Promise<null> => {
      await sleep(75);
      setState({ ...state, open: true, message: message, options: option });
      if (option && option.timer) {
        await sleep(option.timer);
        closeSwal();
      }
      return new Promise((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject };
      });
    },
    [closeSwal]
  );

  const handleResolve = React.useCallback(() => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(null);
    }
    closeSwal();
  }, [closeSwal]);

  const handleReject = React.useCallback(() => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject(null);
    }
    closeSwal();
  }, [closeSwal]);

  const contextValue = React.useRef({ swal, closeSwal });

  return (
    <SweetAlertContext.Provider value={contextValue.current}>
      {children}
      <SweetAlert
        {...state.options}
        message={state.message}
        handleResolve={handleResolve}
        handleReject={handleReject}
        open={state.open}
        fadeOut={state.fadeOut}
      />
    </SweetAlertContext.Provider>
  );
};
