import ReactDOM from 'react-dom';
import styles from './loader.module.css';

export const Loading = ({
  size = '30px',
  loadingStyle = null,
  full = true,
  backgroundColor = 'rgba(0,0,0,0.1)',
}) => {
  return !loadingStyle && full && typeof window !== 'undefined' ? (
    ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          zIndex: 3000,
          top: 0,
          left: 0,
          backgroundColor: backgroundColor,
        }}
      >
        <div
          className={styles.loader}
          style={{
            width: size,
            height: size,
            position: 'absolute',
            top: `calc(50% - (${size} / 2))`,
            left: `calc(50% - (${size} / 2))`,
          }}
        />
      </div>,
      document.body
    )
  ) : (
    <div
      style={
        loadingStyle ?? {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }
      }
    >
      <div className={styles.loader} style={{ width: size, height: size }} />
    </div>
  );
};
