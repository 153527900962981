import { useState, useEffect, useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { useAuthUser } from 'next-firebase-auth';

import { API_URL } from '@constant';

import { fetchWithToken } from './fetchWithToken';

export const FetchUserData = () => {
  const { onErrorRetry } = useSWRConfig();
  const [bearer, setBearer] = useState(null);
  const AuthUser = useAuthUser();

  const updateToken = useCallback(
    () => AuthUser.getIdToken().then((token) => setBearer(`Bearer ${token}`)),
    [AuthUser]
  );

  useEffect(() => {
    if (!bearer && AuthUser.id) updateToken();
  }, [AuthUser, bearer, updateToken]);

  const { data, error, mutate, isValidating } = useSWR<CurrentUser>(
    bearer ? [`${API_URL}/me/`, bearer] : null,
    fetchWithToken,
    {
      onErrorRetry: (error, key, config, revalidate, revalidateOps) => {
        if (error.status === 401) updateToken();

        onErrorRetry(error, key, config, revalidate, revalidateOps);
      },
    }
  );

  return {
    userData: data,
    userDataLoading: !error && !data,
    userDataError: error,
    mutateUserData: mutate,
  };
};
