export const WebView = {
  canSendMessage: () =>
    !!(
      typeof window !== 'undefined' &&
      (window as any).ReactNativeWebView &&
      (window as any).ReactNativeWebView.postMessage
    ),
  sendMessage(
    action: 'onboarding' | 'login' | 'home' | 'logout' | 'application',
    customToken = ''
  ) {
    if (WebView.canSendMessage()) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ action: action, customToken: customToken })
      );
    }
  },
};
