import axios from 'axios';

export const fetchWithToken = (url, token = '') =>
  axios
    .get(url, {
      headers: token
        ? {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        : {
            'Content-Type': 'application/json',
          },
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) throw err.response;
      else throw err;
    });
