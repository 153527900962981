import React from 'react';

export const CategoryContext = React.createContext<Interest[]>([
  {
    name: '',
    slug: '',
  },
] as Interest[]);

export const useCategories = (): Interest[] => React.useContext(CategoryContext);
